<template>
  <div>
    <b-alert :show="showAlert" variant="danger">
      <span>{{ alertMsg }}</span>
    </b-alert>
    <div class="row">
      <div class="col-md-9">
        <div class="card card-custom gutter-b">
          <div class="card-body px-2">
            <DualListBoxDevices
              :source="devices"
              :destination="selectedDevices"
              label="name"
              @onChangeList="onChangeList"
            />
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <DatePicker
              ref="picker"
              v-model="range"
              mode="dateTime"
              :attributes="attributes"
              :masks="masks"
              locale="hr"
              is-range
              is24hr
              is-expanded
              :columns="$screens({ default: 1, lg: 1 })"
              :max-date="new Date()"
            />
            <button
              @click="last7Days"
              class="btn btn-light-primary btn-block font-weight-bold mt-2"
            >
              Last 7 days
            </button>
            <button
              @click="last30Days"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              Last 30 days
            </button>
            <button
              @click="thisMonth"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              This month
            </button>
            <button
              @click="lastMonth"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              Last month
            </button>
            <button
              @click="reset"
              class="btn btn-light-danger btn-block font-weight-bold"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <div class="pt-4">
          <button
            v-if="!isGenerated"
            class="btn btn-primary btn-block font-weight-bold"
            @click="generate"
          >
            Generate
          </button>
          <button
            v-else
            class="btn btn-primary btn-block font-weight-bold"
            @click="resetAll"
          >
            Reset All
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <b-spinner
          v-if="showSpinner"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </div>
    </div>
    <div v-if="isGenerated" class="row mb-6">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pb-0">
            <h3 class="card-title font-weight-bold">Locations Traffic</h3>
          </div>
          <div class="card-body p-0">
            <LocationsTraffic
              v-if="isGenerated"
              :traffic="generatedResults"
              :selected="selectedDevices"
              :range="range"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="isGenerated" class="row mb-6">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pb-0">
            <h3 class="card-title font-weight-bold">Transactions Traffic</h3>
          </div>
          <div class="card-body p-0">
            <TransactionsTraffic
              v-if="isGenerated"
              :traffic="generatedResults"
              :selected="selectedDevices"
              :range="range"
            />
          </div>
        </div>
      </div>
    </div> -->
    <div v-if="isGenerated" class="row mb-6">
      <div class="col-md-6">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pb-0">
            <h3 class="card-title font-weight-bold">
              Average Weekdays Traffic
            </h3>
          </div>
          <div class="card-body p-0">
            <WeekDaysTraffic
              v-if="
                isGenerated &&
                currentActiveModuleComponents?.reports.AverageByDaysInWeek
              "
              :traffic="generatedResults"
              :range="range"
            />
          </div>
        </div>
      </div>
      <!-- MonthTraffic Graph -->
      <div class="col-md-6">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pb-0">
            <h3 class="card-title font-weight-bold">Monthly Traffic</h3>
          </div>
          <div class="card-body p-0">
            <MonthTraffic
              v-if="
                isGenerated &&
                currentActiveModuleComponents?.reports.reportMonth
              "
              :traffic="generatedResults"
              :range="range"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isGenerated" class="row mb-6">
      <div class="col-md-6">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pb-0">
            <h3 class="card-title font-weight-bold">Weekly Traffic</h3>
          </div>
          <div class="card-body p-0">
            <WeekTraffic
              v-if="
                isGenerated && currentActiveModuleComponents?.reports.reportWeek
              "
              :traffic="generatedResults"
              :range="range"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pb-0">
            <h3 class="card-title font-weight-bold">Yearly Traffic</h3>
          </div>
          <div class="card-body p-0">
            <YearTraffic
              v-if="
                isGenerated && currentActiveModuleComponents?.reports.reportYear
              "
              :traffic="generatedResults"
              :range="range"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isGenerated" class="row mb-6">
      <div class="col-md-12 pb-6">
        <div class="card card-custom gutter-b pb-6">
          <div class="card-header border-0 pb-0 pb-6">
            <h3 class="card-title font-weight-bold">Days Traffic</h3>
          </div>
          <div class="card-body p-0">
            <DaysTraffic
              v-if="
                isGenerated &&
                currentActiveModuleComponents?.reports.reportByDaysInRange
              "
              :type="'device'"
              :traffic="generatedResults"
              :range="range"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isGenerated" class="row mb-6">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pb-0">
            <h3 class="card-title font-weight-bold">Average Hourly Traffic</h3>
          </div>
          <div class="card-body p-0">
            <HoursTraffic
              v-if="
                isGenerated &&
                currentActiveModuleComponents?.reports.AverageByHoursOfTheDay
              "
              :traffic="generatedResults"
              :range="range"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import DualListBoxDevices from '@/view/content/components/dual-list-box/DualListBoxDevices'
import ApiService from '@/core/services/api.service'
import format from 'date-fns/format'
import add from 'date-fns/add'
import WeekTraffic from '@/view/pages/finance-report/_components/WeekTraffic'
import HoursTraffic from '@/view/pages/finance-report/_components/HoursTraffic'
import WeekDaysTraffic from '@/view/pages/finance-report/_components/WeekDaysTraffic'
import DaysTraffic from '@/view/pages/finance-report/_components/DaysTraffic'
import startOfMonth from 'date-fns/startOfMonth'
import getMonth from 'date-fns/getMonth'
import addMonths from 'date-fns/addMonths'
import getYear from 'date-fns/getYear'
import endOfMonth from 'date-fns/endOfMonth'
//import TransactionsTraffic from '@/view/pages/finance-report/_components/TransactionsTraffic'
import MonthTraffic from '@/view/pages/finance-report/_components/MonthTraffic'
import YearTraffic from '@/view/pages/finance-report/_components/YearTraffic'
import LocationsTraffic from '@/view/pages/finance-report/_components/LocationsTraffic'
import addDays from 'date-fns/addDays'

export default {
  name: 'DeviceFinanceReport',
  components: {
    DatePicker,
    DualListBoxDevices,
    WeekDaysTraffic,
    DaysTraffic,
    HoursTraffic,
    WeekTraffic,
    MonthTraffic,
    YearTraffic,
    LocationsTraffic,
    //TransactionsTraffic,
  },
  data() {
    return {
      devices: [],
      selectedDevices: [],
      generatedResults: [],
      resultsDays: [],
      totalItems: null,
      range: {
        start: add(new Date(), { days: -1 }).setHours(24, 0, 0),
        end: new Date().setHours(23, 59, 59),
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],
      showAlert: false,
      alertMsg: null,
      base: 0,
      tax: 0,
      total: 0,
      isGenerated: false,
      showSpinner: false,
      isLoaded: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentActiveModuleComponents',
      'currentUserPersonalInfo',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Device', route: '/finance-report/location' },
    ])

    if (
      this.currentUserRole === 'ROLE_ROOT' ||
      this.currentUserRole === 'ROLE_SUPER_ADMIN'
    ) {
      ApiService.get(
        'select-device',
        `?companyId=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          '',
        )}`,
      ).then(({ data }) => {
        this.totalItems = data.length
        this.devices = data
        this.devices = this.devices.filter(
          (device) =>
            (device.isSaldoReducer !== true) & (device.isDemoDevice !== true),
        )
        this.devices.forEach((device, index) => {
          this.devices[index].name =
            device.name + ' | ' + device.location + ' | ' + device.tag
        })
      })
    }

    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER'
    ) {
      ApiService.get(
        'devices',
        `?company=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          '',
        )}&pagination=false&new=1&select=flags,timestamps`,
      ).then(({ data }) => {
        this.totalItems = data.length
        this.devices = [...data['hydra:member']]
        this.devices = this.devices.filter(
          (device) => !device.isSaldoReducer & !device.isDemoDevice,
        )
        this.devices.forEach((device, index) => {
          this.devices[index].name =
            device.name + ' | ' + device.locationName + ' | '
        })
      })
    }

    if (this.currentUserRole === 'ROLE_WATCHER') {
      ApiService.get(
        'users',
        `?email=${this.currentUserPersonalInfo.email}`,
      ).then(({ data }) => {
        const userLocations = data['hydra:member'][0]['locations']

        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            '',
          )}&location.id=${userLocations[0].id}&pagination=false`,
        ).then(({ data }) => {
          this.totalItems = data['hydra:member'].length
          this.devices = [...data['hydra:member']]
          this.devices = this.devices.filter(
            (device) =>
              (device.isSaldoReducer !== true) & (device.isDemoDevice !== true),
          )
        })
      })
    }
  },
  methods: {
    generate() {
      if (
        this.selectedDevices.length === 0 ||
        this.range.start === null ||
        this.range.end === null
      ) {
        this.alertMsg = 'Please select device/s & pick range'
        this.showAlert = true

        return
      }

      this.showSpinner = true
      this.isGenerated = false
      this.generatedResults = []
      let num = 0

      const after = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
      const before = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")

      this.selectedDevices.forEach((device) => {
        ApiService.get(
          'transactions',
          `?device=${device.id}&company=${this.currentUserCompany}&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&pagination=false`,
        ).then(({ data }) => {
          data['hydra:member'].forEach(
            ({
              id,
              imei,
              deviceMeta,
              amount,
              paymentType,
              createdAt,
              locationMeta,
              isSaldoReducer,
            }) => {
              if (!isSaldoReducer && amount >= 0 && paymentType !== 'token_drop' && paymentType !== 'pay_token') {
                this.generatedResults.push({
                  id: id,
                  imei: imei,
                  deviceName: deviceMeta.name,
                  location: locationMeta.title ? locationMeta.title : '',
                  lessor: locationMeta.hasOwnProperty('lessor')
                    ? locationMeta.lessor
                    : '',
                  address: locationMeta.address ? locationMeta.address : '',
                  tags: locationMeta.tags ? locationMeta.tags : [],
                  amount: amount,
                  paymentType: paymentType,
                  createdAt: format(
                    new Date(createdAt),
                    'dd.MM.yyyy @ HH:mm:ss',
                  ),
                })
              }
            },
          )
          num++
          if (num === this.selectedDevices.length) {
            setTimeout(() => {
              this.isGenerated = true
              this.showSpinner = false
            }, 1000)
          }
        })
      })
    },
    last7Days() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -8 }).setHours(24, 0, 0),
        end: add(new Date(), { days: -1 }).setHours(23, 59, 59),
      })
    },
    last30Days() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -31 }).setHours(24, 0, 0),
        end: add(new Date(), { days: -1 }).setHours(23, 59, 59),
      })
    },
    thisMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })

      const today = new Date()

      this.$refs.picker.updateValue({
        start: startOfMonth(today),
        end: today,
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 2,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
    lastMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: startOfMonth(addMonths(new Date(), -1)),
        end: endOfMonth(addMonths(new Date(), -1)),
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 1,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
    reset() {
      // this.locations.push(this.selectedLocations)
      // this.selectedLocations = []

      this.$refs.picker.updateValue({ start: null, end: null })
      // this.range.start = null
      // this.range.end = null
    },
    resetAll() {
      this.generatedResults = []
      this.resultsDays = []
      this.totalItems = null
      this.range.start = null
      this.range.end = null
      this.base = 0
      this.tax = 0
      this.total = 0
      this.isGenerated = false
      this.showSpinner = false
      this.isLoaded = false
    },
    onChangeList: function ({ source, destination }) {
      this.devices = source
      this.selectedDevices = destination
    },
    setStartEndDate() {
      let a = addDays(this.range.start, -1)
      a.setHours(24, 0, 0)
      this.range.start = a

      let b = addDays(this.range.end, 0)
      b.setHours(23, 59, 59)
      this.range.end = b

      this.$refs.picker.updateValue({
        start: this.range.start,
        end: this.range.end,
      })
    },
  },
}
</script>

<style lang="scss">
.card-title {
  display: flex;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}
</style>
